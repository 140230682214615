<template lang="pug">
  v-container(grid-list-md).faq
    certificate-register-dialog(v-model="isCertificateRegisterActive")

    smart-dialog(
      v-if="currentAnswer"
      v-model="showAnswerDialog"
      :title="currentAnswer.question_short"
    )
      .answer(v-html="currentAnswer.answer")

    h2.section-title Региональный оператор персонифированного финансирования
    p Для того, чтобы узнать как получить сертификат в Вашем муниципалитете нажмите на
      a.primary--text(@click="isCertificateRegisterActive = true")  ссылку
    .info-operator
      p
        strong Наименование:
        |  {{ operator.name }}
      p
        strong Адрес:
        |  {{ operator.address_actual }}
      p
        strong Номер телефона:
        |  {{ operator.phone }}
      p
        strong Email:
        |  {{ operator.email_operator }}

    h2.section-title Полезная информация
    p Обратитесь в техническую поддержку, если вы не нашли ответ на свой вопрос
    .info-content
      v-combobox(
        label="Ключевые слова (начните печатать, чтобы увидеть варианты)"
        :items="keywords.items"
        item-text="name"
        item-value="id"
        v-model="keywords.picked"
        :loading="keywords.isLoading"
        :search-input.sync="keywords.search"
        small-chips
        attach
        required
        clearable
        multiple
        hide-no-data
        no-data-text="Совпадений не найдено"
        append-icon="help"
        deletable-chips
        no-filter
      )
    .faq-container
      v-expansion-panels
        v-expansion-panel(
          v-for="(category, idx) in questionsCategories" :key="idx"
        )
          v-expansion-panel-header {{ category.name }}
          v-expansion-panel-content
            v-card-text
              ul
                li.faq-container__question.mb-1(v-for="(question, idx) in category.questions" :key="idx")
                  template(v-if="isLoadingAnswer[question.id]")
                    v-progress-circular.mr-2(indeterminate size="10" color="primary" width="2")
                  a.primary--text(@click="loadAnswer(question.id)") {{ question.question_short }}
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import SmartDialog from '@/components/SmartDialog.vue';

import DirectoryService from '@/services/directory';
import MainService from '@/services/main';

import CertificateRegisterDialog from '@/modules/StartPage/modals/GetCertificateDialog.vue';


export default {
  components: {
    SmartDialog,
    CertificateRegisterDialog,
  },
  data() {
    return {
      keywords: {
        search: '',
        items: [],
        picked: [],
        cache: {},
        isLoading: false,
      },
      questionsCategories: [],
      currentAnswer: null,
      isLoadingAnswer: {},
      isCertificateRegisterActive: false,
      showAnswerDialog: false,
    };
  },
  computed: {
    ...mapGetters({ operator: 'operator/getOperatorInfo' }),
    keywordsPrepared() {
      return this.keywords.picked.map((i) => i.id);
    },
  },
  watch: {
    'keywords.search': function (val) {
      if (this.keywords.isLoading) return;
      this.debouncedSearch(val);
    },
    'keywords.picked': function (val) {
      const toDelete = [];

      for (let i = 0; i < val.length; i++) {
        if (typeof val[i] === 'string') {
          toDelete.push(i);
        }
      }
      this.init();
      for (let i = 0; i < toDelete.length; i++) {
        this.$delete(this.keywords.picked, toDelete[i]);
      }
    },
  },
  created() {
    this.debouncedSearch = _.debounce(this.loadQuestions, 300);
    this.init();
  },
  methods: {
    init() {
      this.$rHandler(
        MainService.getQuestions(this.keywordsPrepared),
        (response) => {
          this.questionsCategories = response.data.data;
        },
      );
    },
    loadQuestions(val = '') {
      if (val in this.keywords.cache) {
        this.keywords.items = this.keywords.cache[val];
      } else {
        this.keywords.isLoading = true;

        DirectoryService.keywordsFaqFiltered(val)
          .then((response) => {
            if (response.data.result_code === 'FLSCS') {
              this.keywords.items = response.data.data;
              this.keywords.cache[val] = response.data.data;
            } else {
              this.keywords.items = [];
            }
          })
          .finally(() => {
            this.keywords.isLoading = false;
          });
      }
    },
    loadAnswer(id) {
      this.isLoadingAnswer[id] = true;

      this.$rHandler(
        MainService.getAnswer(id),
        (response) => {
          this.currentAnswer = response.data.data;
          this.showAnswerDialog = true;
          this.isLoadingAnswer = {};
        },
      );
    },
  },
};
</script>
